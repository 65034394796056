<template>
    <video class="background_video" src="@/assets/video/background.mp4" loop autoplay muted playsinline></video>
    <div class="wrapper">
        <header class="header">
            <a href="/" class="logo">
                <h1 class="title">0JIN &nbsp;2022</h1>
            </a>
        </header>
        <section class="intro_section">
            <div class="big_text_area">
                <span class="text">
                    IT TECHNOLOGY LEARNING CLUB<br />
                    DEV & SECURITY & DESIGN
                </span>
            </div>
        </section>
        <section class="link_section">
            <nav class="navigation">
                <ul class="link_list">
                    <a class="link_item" href="https://yj-sunrin.notion.site/af6819682af24f3f94cbc9f476085de4" target="_blank">
                        <li class="link">INTRO</li>
                    </a>
                    <a class="link_item" href="https://yj-sunrin.notion.site/aff00052aab445dba8353447ca5d0c6a" target="_blank">
                        <li class="link">ACTIVITY</li>
                    </a>
                    <a class="link_item" href="#">
                        <li class="link">RECRUIT</li>
                    </a>
                </ul>
            </nav>
        </section>
        <footer class="footer">
            <div class="description_wrapper">
                <p class="description">L : SEOUL, REPUBLIC OF KOREA</p>
                <div class="description_under">
                    <p class="description">T : 010.3126.4485</p>
                    <p class="description">M : 0jin@sunrin.hs.kr</p>
                </div>
            </div>
        </footer>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>